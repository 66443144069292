define("tortuga-kitchen/templates/components/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c4tSa1qL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\"],[11,\"class\",\"delete\"],[9],[3,\"action\",[[24,0,[]],\"close\"]],[10],[0,\"\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-kitchen/templates/components/notification.hbs"
    }
  });

  _exports.default = _default;
});