define("tortuga-kitchen/templates/components/search-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EWWQdm9m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"field\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"control has-icons-left has-icons-right\"],[9],[0,\"\\n    \"],[7,\"input\"],[11,\"class\",\"input\"],[11,\"placeholder\",\"Hledej jméno...\"],[12,\"value\",[23,\"searchTerm\"]],[12,\"oninput\",[29,\"action\",[[24,0,[]],[25,[\"onChange\"]]],[[\"value\"],[\"target.value\"]]]],[11,\"type\",\"text\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"icon is-small is-left\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"far fa-search\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-kitchen/templates/components/search-box.hbs"
    }
  });

  _exports.default = _default;
});