define("tortuga-kitchen/components/order-card", ["exports", "ember-concurrency", "moment", "tortuga-kitchen/dicts/cancel-reasons", "tortuga-kitchen/dicts/reject-reasons"], function (_exports, _emberConcurrency, _moment, _cancelReasons, _rejectReasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let OrderCardComponent = (_dec = Ember.computed('order.order_time'), _dec2 = Ember.computed('order.is_collapsed'), _dec3 = Ember.computed('order.is_collapsed', 'feedFilters.filters.[]'), _dec4 = (0, _emberConcurrency.task)(function* () {
    yield this.orderManager.pushOrderDown.unlinked().perform(this.order);
  }).drop(), _dec5 = (0, _emberConcurrency.task)(function* (status, otherAttributes = null) {
    yield this.orderManager.changeStatus.unlinked().perform(this.order, status, otherAttributes);
  }).drop(), (_class = (_temp = class OrderCardComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "customerInspector", _descriptor, this);

      _initializerDefineProperty(this, "feedFilters", _descriptor2, this);

      _initializerDefineProperty(this, "optionsModal", _descriptor3, this);

      _initializerDefineProperty(this, "orderManager", _descriptor4, this);

      this.classNames = ['order-card', 'card'];
      this.classNameBindings = ['order.status', 'isCollapsed:order-card--is-collapsed', 'isFrozen:order-card--is-frozen'];
      this.attributeBindings = ['isExpandedForAria:aria-expanded'];
      this.order = {};

      _initializerDefineProperty(this, "pushOrderDown", _descriptor5, this);

      _initializerDefineProperty(this, "changeStatus", _descriptor6, this);
    }

    get isFrozen() {
      // if its after midnight < 5am, we still open yesterday's orders for manipulation
      if ((0, _moment.default)().format('H') < 5) {
        return (0, _moment.default)(this.order.order_time) < (0, _moment.default)().subtract(1, 'day');
      } // older than today


      let threshold = (0, _moment.default)().subtract(1, 'day');
      threshold.set({
        hour: 23,
        minute: 50,
        second: 50,
        millisecond: 0
      });
      return (0, _moment.default)(this.order.order_time) < threshold;
    }

    get isExpandedForAria() {
      return this.order.get('is_collapsed') ? 'false' : 'true';
    } // force expand based on filters (otherwise respect the flag)


    get isCollapsed() {
      // force expand for Pickup, Chest and Trash
      if (this.feedFilters.isGroupActive('readyForPickup') || this.feedFilters.isGroupActive('completed') || this.feedFilters.isGroupActive('trashed')) {
        return false;
      }

      return this.order.is_collapsed;
    }

    toggleExpandCollapse() {
      this.order.set('is_collapsed', !this.order.get('is_collapsed'));
    }

    markOrderAsNew() {
      this.changeStatus.perform('received');
    }

    markOrderAsReadyForPickup() {
      this.changeStatus.perform('made');
    }

    markOrderAsOnTheGrill() {
      this.changeStatus.perform('processing');
    }

    markOrderAsRejectedOrCancelled() {
      // new order = reject
      if (this.order.get('isNew')) {
        const rejectOptions = Object.keys(_rejectReasons.default).map(key => {
          return {
            value: key,
            title: _rejectReasons.default[key].title,
            contextClass: 'is-dark'
          };
        });
        this.optionsModal.open('Odmítnutí objednávky', ['Z jakého důvodu se chystáš tuhle objednávku odmítnout?', 'Zákazník bude o zrušení informován SMSkou.'], 'is-danger', rejectOptions, reason => {
          return this.orderManager.changeStatus.unlinked().perform(this.order, 'rejected', {
            rejected_reason: reason
          });
        });
      } // already spent time = cancel
      else {
          const cancelOptions = Object.keys(_cancelReasons.default).map(key => {
            return {
              value: key,
              title: _cancelReasons.default[key].title,
              contextClass: "is-danger ".concat(_cancelReasons.default[key].affects_score ? '' : 'is-outlined')
            };
          });
          this.optionsModal.open('Zrušení objednávky', ['Z jakého důvodu se chystáš tuhle objednávku zrušit?', 'Tlačítka s výplní jsou důvody, které se odrazí negativně na zákaznikovo skóre.'], 'is-danger', cancelOptions, reason => {
            return this.orderManager.changeStatus.perform(this.order, 'cancelled', {
              cancelled_reason: reason
            });
          });
        }
    }

    markOrderAsCompleted() {
      this.changeStatus.perform('completed');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "customerInspector", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "feedFilters", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "optionsModal", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "orderManager", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isFrozen", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isFrozen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isExpandedForAria", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isExpandedForAria"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isCollapsed"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pushOrderDown", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "changeStatus", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleExpandCollapse", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleExpandCollapse"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markOrderAsNew", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "markOrderAsNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markOrderAsReadyForPickup", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "markOrderAsReadyForPickup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markOrderAsOnTheGrill", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "markOrderAsOnTheGrill"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markOrderAsRejectedOrCancelled", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "markOrderAsRejectedOrCancelled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markOrderAsCompleted", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "markOrderAsCompleted"), _class.prototype)), _class));
  _exports.default = OrderCardComponent;
});