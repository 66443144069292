define("tortuga-kitchen/routes/catchall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CatchallRoute extends Ember.Route {}

  _exports.default = CatchallRoute;
});