define("tortuga-kitchen/templates/components/options-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/XbSQKDq",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"modal-background\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[24,0,[]],\"close\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n  \"],[7,\"article\"],[12,\"class\",[30,[\"message \",[23,\"contextClass\"]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"message-header\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"delete\"],[11,\"aria-label\",\"delete\"],[9],[3,\"action\",[[24,0,[]],\"close\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"message-body\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[1,[23,\"description\"],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"columns is-multiline is-centered\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"options\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"column is-narrow\"],[9],[0,\"\\n            \"],[7,\"button\"],[12,\"class\",[30,[\"button \",[24,1,[\"contextClass\"]]]]],[12,\"onclick\",[29,\"perform\",[[25,[\"chooseOption\"]],[24,1,[\"value\"]]],null]],[12,\"disabled\",[25,[\"chooseOption\",\"isRunning\"]]],[9],[1,[24,1,[\"title\"]],false],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"moreInfo\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"content has-text-grey is-italic\"],[9],[0,\"\\n          \"],[7,\"p\"],[9],[1,[23,\"moreInfo\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"modal-close is-large\"],[11,\"aria-label\",\"close\"],[9],[3,\"action\",[[24,0,[]],\"close\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-kitchen/templates/components/options-modal.hbs"
    }
  });

  _exports.default = _default;
});