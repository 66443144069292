define("tortuga-kitchen/components/options-modal", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let OptionsModalComponent = (_dec = Ember.computed.alias('optionsModal.isOpen'), _dec2 = Ember.computed.alias('optionsModal.title'), _dec3 = Ember.computed.alias('optionsModal.contextClass'), _dec4 = Ember.computed.alias('optionsModal.options'), _dec5 = Ember.computed('optionsModal.body.[]'), _dec6 = Ember.computed('optionsModal.body.[]'), _dec7 = (0, _emberConcurrency.task)(function* (option) {
    const result = yield this.optionsModal.action(option);

    if (result) {
      this.send('close');
    }
  }).drop(), (_class = (_temp = class OptionsModalComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "optionsModal", _descriptor, this);

      this.classNames = ['options-modal', 'modal'];
      this.classNameBindings = ['isOpen:is-active'];

      _initializerDefineProperty(this, "isOpen", _descriptor2, this);

      _initializerDefineProperty(this, "title", _descriptor3, this);

      _initializerDefineProperty(this, "contextClass", _descriptor4, this);

      _initializerDefineProperty(this, "options", _descriptor5, this);

      _initializerDefineProperty(this, "chooseOption", _descriptor6, this);
    }

    get description() {
      return this.optionsModal.body.length ? this.optionsModal.body[0] : '';
    }

    get moreInfo() {
      return this.optionsModal.body.length > 1 ? this.optionsModal.body[1] : '';
    }

    close() {
      this.optionsModal.close();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "optionsModal", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contextClass", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "description", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moreInfo", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "moreInfo"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "chooseOption", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "close", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = OptionsModalComponent;
});