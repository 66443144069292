define("tortuga-kitchen/templates/components/layouts/feed-time-slot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kw2Q1zl1",
    "block": "{\"symbols\":[\"order\"],\"statements\":[[4,\"if\",[[25,[\"orders\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"feed-time-slot__column-slot\"],[11,\"role\",\"button\"],[12,\"id\",[23,\"slotId\"]],[11,\"aria-role\",\"button\"],[9],[0,\"\\n    \"],[7,\"strong\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"icon\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isExpandedAll\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"far fa-angle-up\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"far fa-angle-down\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[9],[1,[23,\"infoLabel\"],false],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"toggleExpandCollapse\"]],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"feed-time-slot__column-orders\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"orders\"]]],null,{\"statements\":[[0,\"      \"],[5,\"order-card\",[],[[\"@order\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-kitchen/templates/components/layouts/feed-time-slot.hbs"
    }
  });

  _exports.default = _default;
});