define("tortuga-kitchen/templates/components/on-off-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u5ql9Rmw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"field\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"id\",\"switchRoundedDanger\"],[12,\"class\",[30,[\"switch is-medium is-rounded \",[29,\"if\",[[25,[\"isOn\"]],\"is-success\",\"is-danger\"],null]]]],[12,\"checked\",[23,\"isOn\"]],[12,\"disabled\",[25,[\"switchAction\",\"isRunning\"]]],[12,\"onclick\",[29,\"perform\",[[25,[\"switchAction\"]],[25,[\"isOff\"]]],null]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n  \"],[7,\"label\"],[11,\"for\",\"switchRoundedDanger\"],[9],[1,[29,\"if\",[[25,[\"isOn\"]],[25,[\"onLabel\"]],[25,[\"offLabel\"]]],null],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-kitchen/templates/components/on-off-switch.hbs"
    }
  });

  _exports.default = _default;
});