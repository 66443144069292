define("tortuga-kitchen/services/notifier", ["exports", "push.js"], function (_exports, _push) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let NotifierService = (_class = (_temp = class NotifierService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      this.isEnabled = false;

      _push.default.Permission.request(() => {
        this.notify('Notifikace zapnuty!');
        this.set('isEnabled', true);
      }, () => {
        this.flashMessages.danger('Doporučuju zapnout notifikace - stačí obnovit stránku a dotaz potvrdit.');
      });
    }

    notify(title = '', text = '') {
      if (!this.isEnabled) {
        return;
      }

      if (!title) {
        return;
      }

      _push.default.create(title, {
        body: text,
        icon: '/assets/icons/favicon-96x96.png',
        timeout: 10000,
        vibrate: true,

        onClick() {
          window.focus();
          this.close();
        },

        onShow() {
          const audio = new Audio('/assets/audio/cheer.mp3');
          var playPromise = audio.play();

          if (playPromise !== undefined) {
            playPromise.catch(() =>
            /*error*/
            {// Safari might not allow playing sound
            });
          }
        }

      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = NotifierService;
});