define("tortuga-kitchen/authenticators/tatrgel", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "tortuga-kitchen/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.api.host + '/oauth/token',
    sendClientIdAsQueryParam: true,
    clientId: _environment.default.api.client_id,

    makeRequest(url, data, headers = {}) {
      if (!data['client_secret']) {
        data['client_secret'] = _environment.default.api.client_secret;
      }

      return this._super(url, data, headers);
    }

  });

  _exports.default = _default;
});