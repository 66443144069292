define("tortuga-kitchen/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ohojRPW7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"error-page\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"article\"],[11,\"class\",\"message is-danger\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"message-header\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"Ajajaj, neco se sesypalo :(\"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"message-body\"],[9],[0,\"\\n        \"],[1,[25,[\"model\",\"message\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-kitchen/templates/error.hbs"
    }
  });

  _exports.default = _default;
});