define("tortuga-kitchen/templates/catchall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b7/ggEYm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"error-page\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"article\"],[11,\"class\",\"message is-dark\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"message-header\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"Stránka nenalezena.\"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"message-body\"],[9],[0,\"\\n        Takovou stránku tu nemáme. Nevadí! Pokračuj na hlavní stránku.\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"has-text-centered\"],[9],[0,\"\\n      \"],[5,\"link-to\",[[13,\"class\",\"button is-primary is-large\"]],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"\\n        Pokračovat na hlavní stranu\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-kitchen/templates/catchall.hbs"
    }
  });

  _exports.default = _default;
});