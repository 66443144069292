define("tortuga-kitchen/services/customer-inspector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CustomerInspectorService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.customer = null;
    }

    inspect(customer) {
      this.set('customer', customer);
    }

    finish() {
      this.set('customer', null);
    }

  }

  _exports.default = CustomerInspectorService;
});