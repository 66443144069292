define("tortuga-kitchen/components/layouts/feed-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let FeedFiltersComponent = (_dec = Ember.computed('feedFilters.filters.[]'), _dec2 = Ember.computed('feedFilters.filters.[]'), _dec3 = Ember.computed('feedFilters.filters.[]'), _dec4 = Ember.computed('feedFilters.filters.[]'), _dec5 = Ember.computed('feedFilters.filters.[]'), (_class = (_temp = class FeedFiltersComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "feedFilters", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);

      this.classNames = ['feed-filters'];
    }

    get isAllActive() {
      return this.feedFilters.isGroupActive('all');
    }

    get isGrillActive() {
      return this.feedFilters.isGroupActive('grill');
    }

    get isPickupActive() {
      return this.feedFilters.isGroupActive('readyForPickup');
    }

    get isChestActive() {
      return this.feedFilters.isGroupActive('completed');
    }

    get isTrashActive() {
      return this.feedFilters.isGroupActive('trashed');
    }

    noFilter() {
      this.feedFilters.resetFilters();
      this.metrics.trackPage({
        page: '/vse',
        title: 'Vse'
      });
    }

    filterByGrill() {
      this.feedFilters.setToGrill();
      this.metrics.trackPage({
        page: '/grill',
        title: 'Grill'
      });
    }

    filterByPickup() {
      this.feedFilters.setToReadyForPickup();
      this.metrics.trackPage({
        page: '/vyzvednout',
        title: 'Vyzvednout'
      });
    }

    filterByChest() {
      this.feedFilters.setToCompleted();
      this.metrics.trackPage({
        page: '/hotovo',
        title: 'Hotovo'
      });
    }

    filterByTrash() {
      this.feedFilters.setToTrashed();
      this.metrics.trackPage({
        page: '/kos',
        title: 'Kos'
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "feedFilters", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isAllActive", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isAllActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isGrillActive", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isGrillActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPickupActive", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isPickupActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isChestActive", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isChestActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isTrashActive", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isTrashActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noFilter", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "noFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterByGrill", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "filterByGrill"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterByPickup", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "filterByPickup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterByChest", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "filterByChest"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterByTrash", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "filterByTrash"), _class.prototype)), _class));
  _exports.default = FeedFiltersComponent;
});