define("tortuga-kitchen/routes/index", ["exports", "ember-concurrency", "tortuga-kitchen/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberConcurrency, _environment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let IndexRoute = (_dec = (0, _emberConcurrency.task)(function* () {
    let attempts = 0;

    while (true) {
      yield (0, _emberConcurrency.timeout)(_environment.default.polling.timeout); // only do long polling this when websocket does not work

      if (!this.websocket.isOnline) {
        try {
          const orders = yield this.store.findAll('order', {
            include: 'order-items',
            reload: true
          });
          attempts = 0;

          this._addNewOrdersToModel(orders);
        } catch (e) {
          attempts++;

          if (attempts > _environment.default.polling.retries) {
            this.flashMessages.danger('Ajaj, asi spadnul server. Zkus obnovit stránku.', {
              sticky: true
            });
            return false;
          }
        }
      }
    }
  }).cancelOn('deactivate').restartable(), (_class = (_temp = class IndexRoute extends Ember.Route.extend(_authenticatedRouteMixin.default, {}) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "kitchenState", _descriptor2, this);

      _initializerDefineProperty(this, "notifier", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "websocket", _descriptor5, this);

      _initializerDefineProperty(this, "pollForOrders", _descriptor6, this);
    }

    beforeModel() {
      super.beforeModel(...arguments);
      this.websocket.connect();
    }

    model() {
      return this.store.query('order', {
        include: 'order-items',
        limit: 5
      });
    }

    afterModel() {
      super.afterModel(...arguments);
      this.pollForOrders.perform();

      this._realtimeOrderLoading();

      this.store.find('setting', '1').then(settings => {
        this.kitchenState.initShop(settings);
      }, () => {
        this.flashMessages.danger('Ajaj, asi spadnul server. Zkus obnovit stránku.', {
          sticky: true
        });
      });
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('model', model.toArray());
    }
    /**
     * Fallback polling in case the socket is down
     */


    /**
     * Push Orders to Model and notify via browser notification
     * In case there are no items in Model, transition to Index
     * (recovery from error state)
     * @param {Array} orders
     */
    _addNewOrdersToModel(orders) {
      const model = this.controllerFor('index').get('model');

      if (!model) {
        this.controllerFor('index').set('model', orders);
        this.transitionTo('index');
      } else {
        const modelOrderIds = model.map(order => order.id); // add orders

        model.addObjects(orders); // notify if order is not present in the model already

        const newOrders = orders.filter(order => !modelOrderIds.includes(order.id));

        if (newOrders.length > 0) {
          this.notifier.notify(newOrders.length === 1 ? 'Nová objednávka' : "Nov\xE9 objedn\xE1vky (".concat(newOrders.length, ")"));
        }
      }
    }
    /**
     * Listen on socket `order.received` event, create Order record from that data and push it to the model
     */


    _realtimeOrderLoading() {
      this.websocket.subscribe('private-orders', [{
        eventName: 'order.received',
        eventHandler: data => {
          const modelClass = this.store.modelFor('order');
          const serializer = this.store.serializerFor('order');
          const normalized = serializer.normalizeQueryResponse(this.store, modelClass, data);

          this._addNewOrdersToModel(this.store.push(normalized));
        }
      }]);
    }

    error() {
      // try to come back up
      this.pollForOrders.perform();
      return true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "kitchenState", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifier", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "websocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pollForOrders", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "error", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = IndexRoute;
});