define("tortuga-kitchen/dicts/reject-reasons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    no_time: {
      title: 'Není čas, nestíháme.'
    },
    missing_product: {
      title: 'Nemáme objednaný produkt.'
    },
    on_request: {
      title: 'Na žádost zákazníka.'
    },
    no_reason: {
      title: 'Bez důvodu.'
    },
    is_invalid: {
      title: 'Je to blbost.'
    }
  };
  _exports.default = _default;
});