define("tortuga-kitchen/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M9UZ3Nci",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layouts/feed-layout\",[],[[\"@orders\",\"@slots\",\"@ordersCountsByStatus\",\"@rawOrders\",\"@loadMoreOrders\"],[[23,\"feedOrders\"],[23,\"feedSlots\"],[23,\"ordersCountsByStatus\"],[23,\"model\"],[23,\"loadMoreOrders\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-kitchen/templates/index.hbs"
    }
  });

  _exports.default = _default;
});