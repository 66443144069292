define("tortuga-kitchen/components/search-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SearchBoxComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['search-box'];
      this.searchTerm = '';
    }

    onChange() {//
    }

  }

  _exports.default = SearchBoxComponent;
});