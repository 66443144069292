define("tortuga-kitchen/controllers/index", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let IndexController = (_dec = Ember.computed('model.@each.{status,order_time}', 'feedFilters.filters.[]'), _dec2 = Ember.computed('feedOrders'), _dec3 = Ember.computed('model.@each.{status}'), _dec4 = (0, _emberConcurrency.task)(function* (cursor, dir) {
    try {
      const params = {
        include: 'order-items',
        limit: 5
      };
      params[dir] = cursor;
      const orders = yield this.store.query('order', params);
      this.model.addObjects(orders.toArray());
    } catch (reason) {
      this.flashMessages.danger('Ajaj, nějak se to porouchalo. Zkus to znovu.');
    }
  }).drop(), (_class = (_temp = class IndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "feedFilters", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "loadMoreOrders", _descriptor3, this);
    }

    get feedOrders() {
      const orders = this.get('model');
      return orders.filter(order => this.feedFilters.isStatusActive(order.status));
    }

    get feedSlots() {
      const orders = this.get('feedOrders');
      return orders.map(order => ({
        slot_id: order.orderTimeSlotId,
        datetime: order.order_time,
        title: order.orderTimeSlotTitle
      })).uniqBy('slot_id').sortBy('datetime');
    }

    get ordersCountsByStatus() {
      const orders = this.get('model');
      return orders.reduce((acc, order) => {
        if (!acc[order.status]) {
          acc[order.status] = 0;
        }

        acc[order.status]++;
        return acc;
      }, {});
    }
    /**
     * @param {string} cursor pagination token
     * @param {string} dir "before" or "after"
     */


  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "feedFilters", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "feedOrders", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "feedOrders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "feedSlots", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "feedSlots"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ordersCountsByStatus", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "ordersCountsByStatus"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadMoreOrders", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IndexController;
});