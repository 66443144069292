define("tortuga-kitchen/initializers/fastclick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    if (typeof FastBoot === 'undefined') {
      Ember.run.schedule('afterRender', function () {
        if (typeof FastClick !== 'undefined') {
          FastClick.attach(document.body);
        }
      });
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});