define("tortuga-kitchen/events/order-status-changed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OrderStatusChangedEvent {
    constructor(newStatus = '') {
      this.category = 'order';
      this.action = 'status_changed';
      this.label = newStatus;
      this.value = 0;
    }

  }

  _exports.default = OrderStatusChangedEvent;
});