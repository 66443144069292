define("tortuga-kitchen/events/order-delayed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OrderDelayedEvent {
    constructor(newOrderTime = '') {
      this.category = 'order';
      this.action = 'delayed';
      this.label = newOrderTime;
      this.value = 0;
    }

  }

  _exports.default = OrderDelayedEvent;
});