define("tortuga-kitchen/services/safe-mode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SafeModeService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.state = true;
    }

    turnOn() {
      this.set('state', true);
    }

    turnOff() {
      this.set('state', false);
    }

  }

  _exports.default = SafeModeService;
});