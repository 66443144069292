define("tortuga-kitchen/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "moment"], function (_exports, _applicationRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationRoute extends Ember.Route.extend(_applicationRouteMixin.default, {}) {
    beforeModel() {
      super.beforeModel(...arguments);
      (0, _moment.default)().locale('cs');
    }

  }

  _exports.default = ApplicationRoute;
});