define("tortuga-kitchen/components/layouts/feed-time-slot", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FeedTimeSlotComponent = (_dec = Ember.computed('orders.@each.is_collapsed'), _dec2 = Ember.computed('slot.slot_id'), _dec3 = Ember.computed('slot.title'), _dec4 = Ember.computed('slot.datetime'), (_class = (_temp = class FeedTimeSlotComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['feed-time-slot'];
      this.classNameBindings = ['orders.length::feed-time-slot--empty'];
    }

    get isExpandedAll() {
      return !this.orders.filter(order => order.is_collapsed).length;
    }

    get slotId() {
      return 'feed-time-slot-' + this.slot.slot_id;
    }

    get title() {
      return this.slot.title;
    }

    get infoLabel() {
      const datetime = (0, _moment.default)(this.slot.datetime);

      if (datetime.format('D') != (0, _moment.default)().format('D')) {
        return datetime.format('dddd');
      }

      return '';
    }

    toggleExpandCollapse() {
      let collapse = this.isExpandedAll;
      this.orders.forEach(order => {
        order.set('is_collapsed', collapse);
      });
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "isExpandedAll", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isExpandedAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "slotId", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "slotId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "title", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "infoLabel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "infoLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleExpandCollapse", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleExpandCollapse"), _class.prototype)), _class));
  _exports.default = FeedTimeSlotComponent;
});