define("tortuga-kitchen/dicts/cancel-reasons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    new_order: {
      title: 'Nová objednávka.'
    },
    no_reason: {
      title: 'Bez důvodu.'
    },
    no_show: {
      title: 'Zákazník nepřišel :(',
      affects_score: true
    },
    on_request: {
      title: 'Na žádost zákazníka.',
      affects_score: true
    }
  };
  _exports.default = _default;
});