define("tortuga-kitchen/services/order-manager", ["exports", "ember-concurrency", "moment", "tortuga-kitchen/events/order-status-changed", "tortuga-kitchen/events/order-delayed"], function (_exports, _emberConcurrency, _moment, _orderStatusChanged, _orderDelayed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let OrderManagerService = (_dec = (0, _emberConcurrency.task)(function* (order, status, otherAttributes = null) {
    order.set('status', status);

    if (otherAttributes !== null) {
      Object.keys(otherAttributes).forEach(key => {
        order.set(key, otherAttributes[key]);
      });
    }

    try {
      yield order.save();
      order.set('failedSave', false);
      this.appLogger.reportToAnalytics(new _orderStatusChanged.default(status));
      return true;
    } catch (reason) {
      order.rollbackAttributes(); // persist for visual indication

      order.set('failedSave', true);
      let serverError = null;

      if (reason.errors && reason.errors.length) {
        serverError = JSON.stringify(reason.errors[0]);
      }

      this.appLogger.error(reason, true, serverError);
      this.flashMessages.danger("Ajaj, nepovedlo se zm\u011Bnit stav objedn\xE1vky. Zkus pros\xEDm znovu.");
      return false;
    }
  }).drop(), _dec2 = (0, _emberConcurrency.task)(function* (order) {
    order.set('order_time', (0, _moment.default)(order.order_time).add(30, 'm').toDate());

    try {
      yield order.save();
      order.set('failedSave', false);
      this.appLogger.reportToAnalytics(new _orderDelayed.default(order.order_time));
    } catch (reason) {
      order.rollbackAttributes(); // persist for visual indication

      order.set('failedSave', true); // check if it's actually just not acceptable order time

      if (reason.errors && reason.errors.length && reason.errors[0].status === 409) {
        this.flashMessages.danger("Objedn\xE1vku nelze posunout mimo otev\xEDrac\xED dobu.");
        return;
      }

      let serverError = null;

      if (reason.errors && reason.errors.length) {
        serverError = JSON.stringify(reason.errors[0]);
      }

      this.appLogger.error(reason, true, serverError);
      this.flashMessages.danger("Ajaj, nepovedlo se zm\u011Bnit \u010Das objedn\xE1vky. Zkus pros\xEDm znovu.");
    }
  }).drop(), (_class = (_temp = class OrderManagerService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appLogger", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "changeStatus", _descriptor3, this);

      _initializerDefineProperty(this, "pushOrderDown", _descriptor4, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appLogger", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "changeStatus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pushOrderDown", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OrderManagerService;
});