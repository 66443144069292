define("tortuga-kitchen/components/layouts/feed-layout", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let FeedLayoutComponent = (_dec = Ember.computed.not('filteredOrdersBySearch.length'), _dec2 = Ember.computed.alias('feedFilters.isDefault'), _dec3 = Ember.computed('orderManager.changeStatus.isRunning'), _dec4 = Ember.computed('orders.[]', 'searchTerm'), _dec5 = Ember.computed('filteredOrdersBySearch.[]', 'slots.[]'), _dec6 = Ember.computed('rawOrders.[]'), _dec7 = Ember.computed('rawOrders.[]'), _dec8 = (0, _emberConcurrency.task)(function* (open) {
    if (open) {
      yield this.kitchenState.openShop.perform();
    } else {
      yield this.kitchenState.closeShop.perform();
    }
  }).drop(), (_class = (_temp = class FeedLayoutComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "feedFilters", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "kitchenState", _descriptor3, this);

      _initializerDefineProperty(this, "orderManager", _descriptor4, this);

      _initializerDefineProperty(this, "websocket", _descriptor5, this);

      this.classNames = ['feed-layout'];
      this.orders = [];
      this.slots = [];
      this.searchTerm = '';

      _initializerDefineProperty(this, "noOrdersToShow", _descriptor6, this);

      _initializerDefineProperty(this, "showPagination", _descriptor7, this);

      _initializerDefineProperty(this, "openCloseShop", _descriptor8, this);
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      this.send('scrollToTop');
    }

    get busy() {
      return this.orderManager.changeStatus.isRunning;
    }

    get filteredOrdersBySearch() {
      if (this.searchTerm !== '') {
        const escapedSearchTerm = this.searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        var re = new RegExp(escapedSearchTerm, 'ig');
        return this.orders.filter(order => re.test(order.customer.get('name')));
      }

      return this.orders;
    }

    get ordersByTimeSlot() {
      return this.slots.map(slot => {
        return {
          slot: slot,
          orders: this.filteredOrdersBySearch.filter(order => order.order_time.getTime() == slot.datetime.getTime())
        };
      });
    }

    get lastOrderPaginationPrevToken() {
      return this.orders.sortBy('order_time').get('firstObject.pagination.prev');
    }

    get lastOrderPaginationNextToken() {
      return this.orders.sortBy('order_time').get('lastObject.pagination.next');
    }

    scrollToTop() {
      document.getElementById('scrollToHere').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "feedFilters", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "kitchenState", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "orderManager", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "websocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "noOrdersToShow", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showPagination", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "busy", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "busy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filteredOrdersBySearch", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "filteredOrdersBySearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ordersByTimeSlot", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "ordersByTimeSlot"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lastOrderPaginationPrevToken", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "lastOrderPaginationPrevToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lastOrderPaginationNextToken", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "lastOrderPaginationNextToken"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "openCloseShop", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "scrollToTop", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToTop"), _class.prototype)), _class));
  _exports.default = FeedLayoutComponent;
});