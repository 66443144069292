define("tortuga-kitchen/services/options-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OptionsModalService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.isOpen = false;
      this.title = '';
      this.body = [];
      this.contextClass = '';
      this.options = [];
    }

    action() {} //

    /**
     *
     * @param {string} title Modal heading
     * @param {array} body Modal body text
     * @param {string} contextClass Contextual class (is-danger, etc)
     * @param {array} options Array of options {value: string, title: string}
     * @param {function} action Action to call on option click {return true to close modal}
     */


    open(title = '', body = [], contextClass = '', options = [], action) {
      this.setProperties({
        title,
        body,
        contextClass,
        options,
        action
      });
      this.set('isOpen', true);
    }

    close() {
      this.set('isOpen', false);
    }

  }

  _exports.default = OptionsModalService;
});