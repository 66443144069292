define("tortuga-kitchen/services/websocket", ["exports", "tortuga-kitchen/config/environment", "pusher-js"], function (_exports, _environment, _pusherJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let WebSocketService = (_class = (_temp = class WebSocketService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appLogger", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      this.isOnline = false;
      this.client = null;
    }

    connect() {
      const {
        access_token
      } = this.get('session.data.authenticated');
      const pusher = new _pusherJs.default(_environment.default.pusher.appKey, {
        cluster: _environment.default.pusher.appCluster,
        forceTLS: true,
        authEndpoint: "".concat(_environment.default.api.host, "/broadcasting/auth"),
        auth: {
          headers: {
            Authorization: "Bearer ".concat(access_token)
          }
        }
      });
      pusher.connection.bind('error', err => {
        if (err.error.data.code === 4004) {
          this.appLogger.log('Pusher connection limit error', err);
          return;
        }
      });
      pusher.connection.bind('state_change', states => {
        this.set('isOnline', states.current === 'connected');

        if (states.current === 'unavailable') {
          return this.flashMessages.warning("Neda\u0159\xED se p\u0159ipojen\xED k serveru v re\xE1ln\xE9m \u010Dase. Objedn\xE1vky budu tedy obnovovat ka\u017Ed\xFDch ".concat(_environment.default.polling.timeout / 1000, " vte\u0159in."));
        }

        if (states.current === 'connected' && states.previous === 'connecting') {
          this.flashMessages.success('Připojení k serveru v reálném čase obnoveno.');
        }
      });
      this.client = pusher;
    }

    subscribe(channelName, handlers = []) {
      const channel = this.client.subscribe(channelName);
      channel.bind('pusher:subscription_error', status => {
        this.flashMessages.danger("Neda\u0159\xED se p\u0159ipojen\xED k objedn\xE1vkov\xE9mu kan\xE1lu. Status: ".concat(status));
        this.appLogger.log("Pusher subscription error to channel: ".concat(channelName));
      });
      handlers.forEach(handler => {
        channel.bind(handler.eventName, handler.eventHandler);
      });
    }

    unsubscribe(channelName) {
      this.client.unsubscribe(channelName);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appLogger", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = WebSocketService;
});