define("tortuga-kitchen/components/utils/has-tap-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HasTapEventsComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.longPressHandler = void 0;
    }

    touchStart() {
      this.longPressHandler = Ember.run.later(() => {
        this.longPress();
      }, 500);
      return true;
    }

    touchEnd() {
      Ember.run.cancel(this.longPressHandler);
      this.longPressHandler = null;
      return true;
    }

    touchCancel() {
      Ember.run.cancel(this.longPressHandler);
      this.longPressHandler = null;
      return true;
    }

    longPress() {//
    }

  }

  _exports.default = HasTapEventsComponent;
});