define("tortuga-kitchen/services/feed-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FeedFiltersService = (_dec = Ember.computed('filters.[]'), (_class = (_temp = class FeedFiltersService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.groups = {
        all: [],
        grill: ['processing'],
        readyForPickup: ['made'],
        completed: ['completed'],
        trashed: ['rejected', 'cancelled']
      };
      this.filters = this.groups.all;
    }

    get isDefault() {
      return this.filters === this.groups.all;
    }

    resetFilters() {
      this.set('filters', this.groups.all);
    }

    setToGrill() {
      this.set('filters', this.groups.grill);
    }

    setToReadyForPickup() {
      this.set('filters', this.groups.readyForPickup);
    }

    setToCompleted() {
      this.set('filters', this.groups.completed);
    }

    setToTrashed() {
      this.set('filters', this.groups.trashed);
    }

    isGroupActive(group) {
      return this.filters === this.groups[group];
    }

    isStatusActive(status) {
      return !this.filters.length || this.filters.includes(status);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "isDefault", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isDefault"), _class.prototype)), _class));
  _exports.default = FeedFiltersService;
});