define("tortuga-kitchen/controllers/login", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let LoginController = (_dec = (0, _emberConcurrency.task)(function* (username, password) {
    this.set('error', null);

    try {
      yield this.session.login.perform(username, password);
    } catch (e) {
      this.set('error', e && e.error === 'invalid_credentials' ? 'Chybné údaje. Zkus znovu.' : 'Přihlášení se nezdařilo. Zkus znovu.');

      if (!e || e.error !== 'invalid_credentials') {
        this.appLogger.error(e);
      }
    }
  }), _dec2 = Ember.computed('login.isRunning', 'username', 'password'), (_class = (_temp = class LoginController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appLogger", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      this.username = '';
      this.password = '';
      this.error = null;

      _initializerDefineProperty(this, "login", _descriptor3, this);
    }

    get isSubmitDisabled() {
      return this.login.isRunning || !this.username || !this.password;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appLogger", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "login", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isSubmitDisabled", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isSubmitDisabled"), _class.prototype)), _class));
  _exports.default = LoginController;
});