define("tortuga-kitchen/router", ["exports", "tortuga-kitchen/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.on('routeDidChange', () => {
        const page = this.currentURL;
        const title = this.currentRouteName || 'unknown';
        this.get('metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
      });
    }

  });
  Router.map(function () {
    this.route('index', {
      path: '/'
    });
    this.route('login', {
      path: '/login'
    });
    this.route('catchall', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});