define("tortuga-kitchen/session-stores/application", ["exports", "ember-simple-auth/session-stores/local-storage"], function (_exports, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localStorage.default.extend({
    key: 'tortuga-auth'
  });

  _exports.default = _default;
});