define("tortuga-kitchen/models/order", ["exports", "ember-data", "moment", "tortuga-kitchen/dicts/cancel-reasons", "tortuga-kitchen/dicts/reject-reasons"], function (_exports, _emberData, _moment, _cancelReasons, _rejectReasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    orderItems: hasMany('order-item'),
    customer: belongsTo('customer'),
    hash_id: attr('string', {
      defaultValue: ''
    }),
    delivery_type: attr('string'),
    payment_type: attr('string'),
    order_time: attr('date'),
    is_takeaway: attr('boolean', {
      defaultValue: false
    }),
    is_delayed: attr('boolean', {
      defaultValue: false
    }),
    rejected_reason: attr('string'),
    cancelled_reason: attr('string'),
    status: attr('string'),
    subtotal_amount: attr('number'),
    delivery_amount: attr('number'),
    extra_amount: attr('number'),
    total_amount: attr('number'),
    formatted_total_amount: attr('string'),
    currency: attr('string'),
    created_at: attr('date'),
    updated_at: attr('date'),
    // hacky pagination
    pagination: attr(),
    // local presentation things
    is_collapsed: attr('boolean', {
      defaultValue: false
    }),
    is_in_grill_feed: attr('boolean', {
      defaultValue: true
    }),
    isCompleted: Ember.computed('status', function () {
      return this.get('status') === 'completed';
    }),
    isReadyForPickup: Ember.computed('status', function () {
      return this.get('status') === 'made';
    }),
    isOnTheGrill: Ember.computed('status', function () {
      return this.get('status') === 'processing';
    }),
    isNew: Ember.computed('status', function () {
      return ['received', 'accepted'].includes(this.get('status'));
    }),
    isTrashed: Ember.computed('status', function () {
      return ['rejected', 'cancelled'].includes(this.get('status'));
    }),
    isDelayedAndPending: Ember.computed('status', 'is_delayed', function () {
      return this.get('status') === 'ignored' && this.get('is_delayed');
    }),
    isCancelledByCustomer: Ember.computed('status', 'cancelled_reason', function () {
      return this.get('status') === 'cancelled' && this.get('cancelled_reason') === 'delayed_order';
    }),
    cancelledReason: Ember.computed('status', 'cancelled_reason', function () {
      return this.get('status') === 'cancelled' && this.get('cancelled_reason') ? "Zru\u0161eno: ".concat(_cancelReasons.default[this.get('cancelled_reason')].title) : null;
    }),
    rejectedReason: Ember.computed('status', 'rejected_reason', function () {
      return this.get('status') === 'rejected' && this.get('rejected_reason') ? "Odm\xEDtnuto: ".concat(_rejectReasons.default[this.get('rejected_reason')].title) : null;
    }),
    formattedTotalAmount: Ember.computed.alias('formatted_total_amount'),
    hasHighPriceWarning: Ember.computed('total_amount', function () {
      // TODO: configurable number, in cents
      return this.get('total_amount') > 700 * 100;
    }),
    orderTimeSlotTitle: Ember.computed('order_time', function () {
      return (0, _moment.default)(this.get('order_time')).format('HH:mm');
    }),
    orderTimeSlotId: Ember.computed('order_time', function () {
      return (0, _moment.default)(this.get('order_time')).format('YYYYMMDDHHmm');
    }),
    hashIdFormatted: Ember.computed('hash_id', function () {
      const hashId = this.hash_id || '';
      return "".concat(hashId.substr(0, 3), "-").concat(hashId.substr(3));
    })
  });

  _exports.default = _default;
});