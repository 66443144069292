define("tortuga-kitchen/events/kitchen-opened", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class KitchenOpenedEvent {
    constructor() {
      this.category = 'kitchen';
      this.action = 'opened';
      this.label = 'control_panel';
      this.value = 0;
    }

  }

  _exports.default = KitchenOpenedEvent;
});