define("tortuga-kitchen/templates/components/customer-details-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yp0MN7JW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"modal \",[29,\"if\",[[25,[\"show\"]],\"is-active\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-background\"],[11,\"role\",\"button\"],[11,\"aria-role\",\"button\"],[9],[3,\"action\",[[24,0,[]],\"closeModal\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-card\"],[9],[0,\"\\n    \"],[7,\"header\"],[11,\"class\",\"modal-card-head\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"modal-card-title\"],[9],[7,\"i\"],[11,\"class\",\"fas fa-user-circle\"],[9],[10],[0,\" Customer: Name\"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"delete\"],[11,\"aria-label\",\"close\"],[9],[3,\"action\",[[24,0,[]],\"closeModal\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"modal-card-body is-size-5\"],[9],[0,\"\\n      \"],[7,\"strong\"],[9],[0,\"Status:\"],[10],[0,\" \"],[7,\"span\"],[11,\"class\",\"tag is-success\"],[9],[0,\"Trusted\"],[10],[0,\" (12 orders, 0 rejects, 0 cancels)\"],[7,\"br\"],[9],[10],[0,\"\\n      \"],[7,\"strong\"],[9],[0,\"Facebook:\"],[10],[0,\" \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"(preview)\"],[10],[7,\"br\"],[9],[10],[0,\"\\n      \"],[7,\"strong\"],[9],[0,\"Mobile number:\"],[10],[0,\" 711456908\"],[7,\"br\"],[9],[10],[0,\"\\n      \"],[7,\"strong\"],[9],[0,\"Registered on:\"],[10],[0,\" 12th May 2019\"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-kitchen/templates/components/customer-details-modal.hbs"
    }
  });

  _exports.default = _default;
});