define("tortuga-kitchen/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "tortuga-kitchen/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.api.host,
    namespace: 'api',

    authorize(xhr) {
      let {
        access_token
      } = this.get('session.data.authenticated');

      if (Ember.isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
      }
    }

  });

  _exports.default = _default;
});